@import "~scss/imports";

.BigNumbers {
  text-align: center;
}

.BigNumber {
  display: inline-block;
  margin: 1rem 0;
  padding: 0 1.5rem;
  position: relative;
  text-align: center;

  &:first-child {
    border-right: 1px solid var(--indexblue);
  }

  @include breakpoint(tab) {
    padding: 0 3rem;
  }
}

.BigNumbers-number {
  @extend %font-bignumber;
  font-size: 48px;

  @include breakpoint(tab) {
    font-size: 120px;
  }
}

.BigNumbers-label {
  @extend %font-headline-2;
  font-size: 24px;

  @include breakpoint(tab) {
    font-size: 40px;
  }
}
