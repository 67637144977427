@import "~scss/imports.scss";

.Footnote {
  @extend %font-copymono;
  display: block;

  a {
    color: inherit;
  }

  + .Footnote {
    margin: 0;
  }
}

.TextBlock-single + .Footnote {
  padding-top: 20px;

  @include breakpoint(tab) {
    padding-top: 80px;
  }
}
