$break-mobile: 500px !default;
$break-tablet: 768px !default;
$break-desktop: 1100px !default;

$breakpoints: (
  "mob": (
    min-width: $break-mobile
  ),
  "tab": (
    min-width: $break-tablet
  ),
  "desk": (
    min-width: $break-desktop
  )
);

@mixin breakpoint($name) {
  @media #{inspect(map-get($breakpoints, $name))} {
    @content;
  }
}
