@import "~scss/imports";

.PercentBlockGrid__items {
  margin: 0 auto;
  padding: 0;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;

  &.PercentBlockGrid__items-count--1 {
    grid-gap: 0;
    grid-template-columns: auto;

    @include breakpoint(tab) {
      grid-template-columns: repeat(3, 1fr);
      .PercentageBlock:first-child {
        grid-column: 2;
      }
    }
  }

  .PercentageBlock {
    grid-column: span 2;
  }
  .PercentageBlock:nth-child(1),
  .PercentageBlock:nth-child(2) {
    grid-column: span 3;
  }

  .PercentageBlock--double {
    grid-column: span 2;
    padding: 1rem 0 2rem;
  }

  .PercentageBlock--double:nth-child(1),
  .PercentageBlock--double:nth-child(2) {
    grid-column: span 3;
  }
}

.PercentBlockGrid__items-count--2 {
  @include breakpoint(tab) {
    grid-gap: 5rem;
    grid-template-columns: repeat(6, 1fr);
    max-width: 800px;

    .PercentageBlock {
      justify-self: center;
      width: 80%;
    }
  }
}

.PercentBlockGrid__items-count--9 {
  display: block;
  @include breakpoint(tab) {
    display: grid;
  }
}

.PercentageBlock {
  list-style: none;
  padding: 1rem 0;

  &--double {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    justify-content: flex-start;
    //margin: 1rem;
    //width: 33%;

    svg {
      height: auto;
      width: 50%;
    }

    .PercentageBlock__percentage {
      font-size: 2rem;
    }
  }
}

.PercentageBlock__percentage {
  font-family: $ff-mono-regular;
  font-size: 3rem;
}

.PercentageBlock-text {
  height: 100%;
  padding: 1rem 1rem 0;
  width: 100%;
}

.PercentageBlock-copy {
  @extend %font-copy;
  padding: 1rem 0;
  text-align: center;
}

.DataRow {
  margin: 1rem 0;

  .PercentBlockGrid {
    li {
      padding: 1rem;
    }

    @include breakpoint(mob) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
    }

    @include breakpoint(tab) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2rem;

      li {
        padding: 2rem;
      }
    }
  }
}

.Helper-text {
  @extend %font-copymono;
  display: block;
  max-width: 450px;
  margin: auto;
  text-align: center;
}
