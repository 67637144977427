@import "~scss/imports.scss";

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: subpixel-antialiased;
  color: #2b2b33;
  background: #fff;
}

h1 {
  font-family: $ff-medium;
  font-size: 60px;
  font-weight: 500;
  line-height: 72px;
}

h2 {
  font-family: $ff-medium;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
}

@media (max-width: $break-mobile) and (orientation: portrait),
  (max-height: $break-mobile) and (orientation: landscape) {
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
}

em {
  font-style: italic;
}
