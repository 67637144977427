@import "~scss/imports";
// @import "~scss/type.scss";

// TODO: move out - not really part of intro
.main-nav {
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 1000;

  button {
    cursor: pointer;
    border: 0;
    height: 14px;
    width: 36px;
    padding: 0;
    outline: none;
    background: transparent;
  }

  @media (max-width: $break-mobile) and (orientation: portrait),
    (max-height: $break-mobile) and (orientation: landscape) {
    top: 22px;
    left: calc(50% - 18px);
  }
}

.debug {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  z-index: 1000;
  font-family: monospace;
  font-size: 14px;
  text-align: left;
  padding: 20px;
}

.intro-container {
  position: relative;
  text-align: center;

  h2,
  .conclusion-text {
    max-width: 800px;
    @extend %font-headline-2;

    p + p {
      margin-top: 1em;
    }

    sup {
      @extend %font-copymono;
      vertical-align: super;
    }
  }

  .stage {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .center-wrapper {
      height: 100%;
      width: 100%;
    }
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;

    section {
      width: 100%;
    }
  }

  .wework-fow-logo {
    margin: 32px 0 60px 0;

    @media (max-width: $break-mobile) and (orientation: portrait) {
      margin: 85px 0 160px 0;
    }

    @media (max-height: $break-mobile) and (orientation: landscape) {
      margin: 54px 0 60px 0;
    }
  }

  .sticky-header {
    position: sticky;
    top: 21px;

    @media (max-width: $break-mobile) and (orientation: portrait),
      (max-height: $break-mobile) and (orientation: landscape) {
      top: 41px;
    }

    header {
      @extend %font-copymono;
    }

    article {
      position: relative;
      margin-top: 12vh;
      padding: 0 20px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;

      aside {
        @extend %font-copymono;
        max-width: 420px;
        margin: 36px auto 0 auto;

        sup {
          font-size: 10px;
          font-weight: normal;
          line-height: 18px;
        }
      }

      .sticky-header-section {
        position: absolute;
        top: 0;
        opacity: 0;
        transition: opacity 330ms ease-out, transform 400ms ease-in;

        padding: 16px;

        will-change: opacity;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        transform: translateZ(0);
      }
    }
  }

  .city-container {
    z-index: 1;
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    will-change: opacity;
  }

  // Wave
  .wave-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4;
    transform: translateY(0);
    will-change: transform;
    backface-visibility: hidden;
    transform-style: preserve-3d;

    svg {
      display: block;
      width: 100%;
    }
  }

  //Conclusion
  .conclusion-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    z-index: 3;

    padding: 16px;
  }
}
