@import "~scss/imports";

.KeyFinding-header {
  align-items: center;
  padding-bottom: 30px;
  position: relative;

  &:after {
    height: 1px;
    background-color: var(--indexblue);
    left: 30px;
    right: 30px;
    content: "";
    position: absolute;
    bottom: 0;
  }

  @include breakpoint(tab) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 0;

    &:after {
      display: none;
    }
  }
}
.KeyFinding-header-heading {
  align-self: center;
  padding: 0 2rem;
  text-align: center;
  @extend %font-keyfindingheading;

  @include breakpoint(tab) {
    padding: 36px;
    text-align: left;
  }
}

.KeyFinding-header-image {
  display: block;
  height: auto;
  margin: 0 auto;
  width: 70%;

  @include breakpoint(tab) {
    width: 100%;
  }
}

.KeyFinding-body {
  max-width: 1225px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1em;

  > .Finding:first-of-type {
    margin-top: calc(30px - 1rem);
  }
}

.Finding {
  margin-top: 40px;
  padding-top: 40px;
  position: relative;
}

.Finding-inner {
  padding: 1rem;

  @include breakpoint(tab) {
    padding: 2rem;
  }
}

.Finding-header {
  margin-bottom: 3rem;
}

.Finding-header-heading {
  border-bottom: 1px solid var(--indexblue);
  @extend %font-findingheading;
  padding-bottom: 1rem;
  align-items: center;
  display: flex;
  justify-content: space-between;

  .HowDidWe {
    flex-shrink: 0;
    margin-left: 1rem;
  }
}

.Finding-header-textwrap {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
}

.Finding-header-text {
  @extend %font-copy;

  max-width: 800px;

  strong {
    @extend %font-subhead;
  }

  ul {
    margin-left: 1rem;
    padding: 0.5rem 0 0.5rem 0.5rem;
  }

  p + p {
    margin-top: 1rem;
  }

  @include breakpoint(tab) {
    width: 60%;
  }

  @include breakpoint(desk) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
}

.KeyFindingsNav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  @include breakpoint(tab) {
    grid-template-columns: repeat(3, 1fr);
    margin: 0 1rem;
  }

  @include breakpoint(desk) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.KeyFindingsNav-more {
  margin: 2rem 0 1rem;
}

.KeyFindNavItem {
  list-style: none;
  padding: 1rem 0;
  margin: 0 1rem;

  a {
    color: inherit;
    display: block;
    text-decoration: none;
  }

  @include breakpoint(tab) {
    margin: 0;
    padding: 1rem;
  }
}

.KeyFindNavItem-label {
  @extend %font-bigcopy;
  text-align: center;
}

.KeyFindNavItem-tooltip {
  background-color: #fff;
  border: 1px solid var(--indexblue);
  border-radius: 0;
  color: #000;
  opacity: 1 !important;
  padding: 1rem 2rem;
  text-align: center;
  max-width: 300px;
  @extend %font-copymono;

  &:before {
    border: none !important;
  }
  &:after {
    border: none !important;
  }
}

.NextFinding {
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 400px;
  margin: auto;
}
