@import "~scss/imports";

.EconomicOptimism-X-axis {
  @extend %font-chartaxis;
  text-transform: uppercase;
  text-align: center;
  margin-top: 20px;
}


.ScatterPlot-label {
  @extend %font-copymono;
  text-transform: uppercase;
}

.EconomicOptimism-percentage {
  @extend %font-copymono;
}

.EconomicOptimism-reveal .EconomicOptimism-percentage {
  display: block;
}
.EconomicOptimism-hide .EconomicOptimism-percentage {
  display: none;
}
