@import "~scss/imports.scss";

.NestedBlock {
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 1em;

  .NestedBlock-insights-item {
    margin-top: 2rem;
  }
  @include breakpoint(tab) {
    grid-template-columns: 300px 1fr;
    grid-column-gap: 8vw;
    .NestedBlock-insights-item {
      margin-top: 4rem;
    }
  }
  svg {
    height: auto;
    width: 100%;
  }

  .Footnote {
    margin-top: 1em;
  }
}

.NestedBlock-number {
  @extend %font-copymono;
}

.NestedBlock-label {
  @extend %font-chartaxis;
}

.NestedBlock-insights-item-heading {
  @extend %font-insightheading;
}

.NestedBlock-insights-item-explainer {
  @extend %font-smallcopy;
  margin-top: 1em;
}
