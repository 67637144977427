@import "~scss/imports";
@import "~scss/breakpoints.scss";

.GlobalHeader {
  background-color: #ffffff;
  height: var(--globalheader-height);
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: #000000;
    text-decoration: none;
  }

  header {
    @extend %font-copymono;
    padding-bottom: 0;
  }
}

.GlobalHeader-inner,
#Introduction header {
  display: none;

  @media (min-width: 550px) {
    align-items: center;

    display: flex;
    justify-content: center;
  }
}
