@import "~scss/imports";

$xl: 1420px;
$lg: 1220px;

.CityTopChart {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 10px;
  padding: 1rem 0;

  // svg {
  //   height: auto;
  //   width: 100%;
  // }

  // @include breakpoint(tab) {
  //   grid-template-columns: 30px 1fr 3fr;
  // }

  // @include breakpoint(desk) {
  //   grid-template-columns: 20px 1fr 3fr;
  // }

  // @media (min-width: $lg) {
  //   grid-template-columns: 30px 1fr 3fr;
  // }

  // @media (min-width: $xl) {
  //   grid-template-columns: 30px 1fr 4fr;
  // }
}

.CityTopChart-title {
  @extend %font-bigcopy;
  margin-left: calc(20px + 0.5rem);
  padding: 1rem 0 0.5rem;

  @include breakpoint(tab) {
    margin-left: calc(30px + 0.5rem);
    padding: 1rem 0 0.5rem 0.5rem;
    white-space: nowrap;
  }

  @include breakpoint(desk) {
    margin-left: calc(20px + 0.5rem);
  }
}

.CityTopChart-label {
  @extend %font-copymono;
  font-size: 12px;
}

.CityTopChart-Y-axis {
  position: relative;

  span {
    line-height: 0;
    left: 0;
    display: block;
    white-space: nowrap;
    padding: 0 1rem;
    position: absolute;
    text-transform: uppercase;
    transform-origin: left;
    transform: rotate(90deg) translate(-10%, -10px);

    @extend %font-chartaxis;

    &:first-child {
      bottom: 100%;
    }
    &:last-child {
      bottom: 0;
      transform: rotate(90deg) translate(-95%, -10px);
    }
  }
}

.CityTopChart-list {
  @extend %font-mediumcopy;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: repeat(9, 1fr);
  list-style: none;

  @include breakpoint(desk) {
    font-size: 14px;
  }

  @media (min-width: $lg) {
    font-size: 16px;
  }
}

.CityTopChart-list-item {
  display: flex;
  // align-self: center;
  // max-width: 350px;
  // display: flex;
  // flex-wrap: wrap;
  align-items: center;
}
.CityTopChart-list-item-value {
  display: block;
  width: 80px;
  flex-shrink: 0;
  margin-right: 0.5em;
}
.CityTopChart-list-item-label {
  align-items: center;
  display: flex;
}
.CityTopChart-list-item-label-text {
  margin-right: 0.5em;
}
