@import "~scss/imports.scss";

.Loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.LoaderCopy {
  @extend %font-copymono;
}

.PageLoading {
  background-color: var(--tan);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  @extend %font-copymono;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.4;
  }
}
.PageLoading-text {
  animation: blink 1s infinite alternate;
}
