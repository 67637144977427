@import "~scss/imports";

.Columns {

  @include breakpoint(desk) {
    display: grid;
    grid-auto-columns: 1fr;

    .Column {
      grid-row: 1;
    }
  }
}

.Column {
  display: inline-block;
  margin: 1rem 0;
  padding: 0 1.5rem;
  position: relative;

  @include breakpoint(desk) {
    &+.Column {
      border-left: 1px solid var(--indexblue);
    }
  }
}

.Columns-text {
  @extend %font-copy;

  p + p {
    margin-top: 1rem;
  }

  a {
    color: var(--indexblue);
    text-decoration: none;
  }
}

.Columns-number {
  @extend %font-bignumber;
  font-size: 48px;

  @include breakpoint(tab) {
    font-size: 120px;
  }
}

.Columns-label {
  @extend %font-headline-2;
  font-size: 24px;
  padding-bottom: 1rem;
  text-align: center;

  @include breakpoint(tab) {
    font-size: 40px;
  }

  @include breakpoint(desk) {
    padding-bottom: 2rem;
  }
}
