@import "~scss/imports";

@mixin type($background-color) {
  background-color: $background-color;
  &.place-top {
    &:after {
      border-top-color: $background-color;
      border-top-style: solid;
      border-top-width: 6px;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom-color: $background-color;
      border-bottom-style: solid;
      border-bottom-width: 6px;
    }
  }
  &.place-left {
    &:after {
      border-left-color: $background-color;
      border-left-style: solid;
      border-left-width: 6px;
    }
  }
  &.place-right {
    &:after {
      border-right-color: $background-color;
      border-right-style: solid;
      border-right-width: 6px;
    }
  }
}
@mixin border($border-color) {
  border: 1px solid $border-color;
  &.place-top {
    &:before {
      border-top: 8px solid $border-color;
    }
  }
  &.place-bottom {
    &:before {
      border-bottom: 8px solid $border-color;
    }
  }
  &.place-left {
    &:before {
      border-left: 8px solid $border-color;
    }
  }
  &.place-right {
    &:before {
      border-right: 8px solid $border-color;
    }
  }
}

.Tooltip.type-dark {
  color: black;
  @include type(white !important);
  @include border(#0087b1);
  border-radius: 0;
  &:before,
  &:after {
    display: none;
  }
}

.Tooltip {
  ul {
    list-style-type: none;
    padding: 0;
  }
  .value {
    font-size: 1.2rem;
  }
}

.MovingMapTooltip-inner-region {
  @extend %font-copy;
  text-align: center;
}

.MovingMapTooltip-inner-values {
  display: grid;
  grid-auto-columns: 1fr;
  margin-top: 1em;
  .MovingMapTooltip-inner-total {
    grid-row: 1;
    width: 100%;
    & + .MovingMapTooltip-inner-total {
      border-left: 1px solid #0087b1;
    }
  }
}

.MovingMapTooltip-inner-total {
  @extend %font-smallcopy;
  margin-bottom: 10px;
  text-align: center;
  padding: 0 1em;

  .value {
    @extend %font-chartnumber;
    display: block;
    font-size: 2rem;
    margin-top: 0.5rem;
  }
}
.MovingMapTooltip-inner-list {
  @extend %font-copymono;
}

.Finding--map-block {
  background-color: #c5e7ec;
  padding: 0;
  position: relative;

  .Finding-header {
    padding: 1rem 0 0;
    margin-bottom: 1em;
  }

  @include breakpoint(tab) {
    .Finding-header {
      padding: 2rem;
    }
    .Finding-header-text {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1em;
      width: 100%;
      max-width: 100%;

      p {
        width: 100%;
      }
      p + p {
        margin-top: 0;
      }
    }
  }
  .Finding-header-text {
    flex-wrap: nowrap;
  }
}

.MovingMap {
  min-height: 200px;
}

.MovingMap-footnote {
  @extend %font-copymono;
  display: block;
  font-size: 0.5rem;
  padding-top: 1rem;

  @include breakpoint(tab) {
    font-size: 0.75rem;
  }
}
