@import "~scss/imports";

.AboutModule {
  background-color: var(--tan);
  padding: 70px 1em 100px;
  text-align: center;

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}

.AboutModule-note,
.AboutModule-explainer {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 3rem;
}

.AboutModule-explainer {
  @extend %font-bigcopy;
}
.AboutModule-note {
  @extend %font-copy;
}

.AboutModule .BigNumbers {
  margin-top: 3rem;
}

.AboutNote {

}

.AboutModule-note + .LinkButton {
  margin-top: 3rem;
}

.LinkButton {
  @extend %font-subhead;
  background-color: #25BDA3;
  color: #2B2B33;
  display: inline-block;
  padding: 1rem 3rem;
  margin: 1rem 0;
  text-decoration: none;
}
