@import "~scss/imports";

.RadialGraphs + .TextBox {
  @include breakpoint(desk) {
    margin-top: -3rem;
  }
}

.RadialGraph {
  border-bottom: 1px solid var(--indexblue);
  height: auto;
  width: 100%;

  @include breakpoint(tab) {
    border-bottom: none;
    width: 33.33%;

    &:nth-child(2) .RadialGraph-content {
      border-left: 1px solid var(--indexblue);
      border-right: 1px solid var(--indexblue);
    }
  }
}

.RadialGraph-copy {
  @extend %font-smallcopy;
  padding: 1rem 0.5rem;

  @include breakpoint(tab) {
    min-height: 150px;
  }

  @include breakpoint(desk) {
    padding: 1rem 2rem 1rem 0;
  }
}
