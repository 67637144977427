@import "~scss/imports.scss";
@import "~scss/reset.scss";
@import "~scss/type.scss";
@import "~scss/base.scss";
@import "~scss/ui.scss";
@import "~scss/breakpoints.scss";

@font-face {
  font-family: "ApercuPro";
  src: url("/fonts/ApercuPro-Medium.eot");
  src: url("/fonts/ApercuPro-Medium.eot?#iefix") format("embedded-opentype"),
    url("/fonts/ApercuPro-Medium.woff2") format("woff2"),
    url("/fonts/ApercuPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "ApercuPro";
  src: url("/fonts/ApercuPro-Regular.eot");
  src: url("/fonts/ApercuPro-Regular.eot?#iefix") format("embedded-opentype"),
    url("/fonts/ApercuPro-Regular.woff2") format("woff2"),
    url("/fonts/ApercuPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ApercuWeWorkMono-Regular";
  src: url("/fonts/ApercuWeWorkMono-Regular.eot");
  src: url("/fonts/ApercuWeWorkMono-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/ApercuWeWorkMono-Regular.woff2") format("woff2"),
    url("/fonts/ApercuWeWorkMono-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {
  --gray: #d8d8d8;
  --indexblue: #0087b1;
  --tooltipvalue: #00a18d;
  --tan: #f8f1eb;

  --apercu-pro: "ApercuPro", sans-serif;
  --apercu-medium: "ApercuPro", sans-serif;

  --apercu-mono: "ApercuWeWorkMono-Regular", sans-serif;

  --navbutton-height: 50px;
  --globalheader-height: 60px;

  --size-keyfindingheading: 40px;
  --size-findingheading: 26px;
  --size-copy: 16px;
  --line-copy: 1.2;
  --size-copybig: 20px;
  --line-copybig: 1.2;

  --size-headline-2: 24px;
  --line-headline-2: 1.2;

  --size-copysm: 14px;
  --line-copysm: 1.4;
  --size-copymd: 16px;
  --line-copymd: 1.4;

  @include breakpoint(tab) {
    --size-headline-2: 36px;
    --size-copy: 20px;
    --size-copybig: 24px;
    --size-headline-2: 40px;
    --size-findingheading: 40px;
    --size-keyfindingheading: 60px;
  }
}

sup {
  vertical-align: super;
  @extend %font-copymono;
}

.ff-medium {
  font-family: $ff-medium;
}

.ff-regular {
  font-family: $ff-regular;
}

.ff-mono-regular {
  font-family: $ff-mono-regular;
}

.type-uppercase {
  text-transform: uppercase;
}

.MovingMap {
  position: relative;
}

.MovingMap-controls {
  display: flex;
  position: absolute;
  right: 1em;
  bottom: 1em;
  z-index: 1;
}

.MovingMap-controls button {
  background-color: black;
  color: white;
  border: 0;
  font-size: 2em;
  width: 3rem;
  height: 3rem;
  margin: 0.125em;
}

.MovingMap svg *:focus {
  outline: 0;
}

.pointer-none {
  pointer-events: none;
}

.Block-header {
  border-bottom: 1px solid var(--indexblue);
  display: block;
  padding-bottom: 0.25rem;
  @extend %font-headline-2;

  @include breakpoint(tab) {
    margin: 0 1rem;
  }
}

.Block-header-xl {
  border-bottom: 1px solid var(--indexblue);
  display: block;
  padding-bottom: 0.25rem;
  margin: 0 1rem;
  @extend %font-keyfindingheading;
}

.ComparedToText {
  @extend %font-copymono;
  display: block;
  text-align: center;
}

.Pad {
  padding: 1rem;
}

button:focus {
  outline: none;
  // outline-color: var(--indexblue);
  // outline-style: solid;
  // outline-width: thin;
}
