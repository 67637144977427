// Import fonts
// --------------------------------------------------

$fonts: (
  ("keyfindingheading", "keyfindingheading", "apercu-pro", 500),
  ("findingheading", "findingheading", "apercu-pro", 500),
  ("copy", "copy", "apercu-pro", 400),
  ("bigcopynormal", "copybig", "apercu-pro", 400),
  ("bigcopy", "copybig", "apercu-pro", 500),
  ("bignumber", "bignumber", "apercu-pro", 500),
  ("headline-2", "headline-2", "apercu-medium", 500),
  ("subhead", "copy", "apercu-medium", 500),
  ("copymono", "copysm", "apercu-mono", 100),
  ("smallcopy", "copysm", "apercu-pro", 100),
  ("mediumcopy", "copymd", "apercu-pro", 100),
  ("chartaxis", "copysm", "apercu-medium", 500),
  ("chartnumber", "copybig", "apercu-mono", 400),
  ("insightheading", "copybig", "apercu-pro", 500)
);

@each $font in $fonts {
  %font-#{nth($font,1)} {
    font-family: var(--#{nth($font, 3)});
    font-size: var(--size-#{nth($font, 2)});
    font-weight: #{nth($font, 4)};
    line-height: var(--line-#{nth($font, 2)});
  }
}
