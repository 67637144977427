@import "~scss/imports.scss";

.StackedBlocks-item {
  margin: 0.5rem;
  width: calc(50% - 1rem);

  @include breakpoint(tab) {
    width: calc(33.33% - 1rem);
  }

  @include breakpoint(desk) {
    width: calc(20% - 1rem);
  }
  small {
    margin-top: 1rem;
  }
}

.StackedBlocks-small .StackedBlocks-item {
  width: calc(50% - 1rem);

  @include breakpoint(tab) {
    width: calc(25% - 1rem);
  }

  @include breakpoint(desk) {
    width: calc(14% - 1rem);
  }
}

.StackedBlock {
  position: relative;
}

.StackedBlock-label {
  font-family: $ff-mono-regular;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
}

.StackedBlock-label-heading {
  font-size: 14px;
  min-height: 2em;
}

.StackedBlock-label-value {
  font-size: 40px;
}
