@import "~scss/imports";

.Nav {
  background-color: var(--indexblue);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  color: #fff;
  padding: 5px 15px 30px;
  height: 100vh;
  overflow-y: auto;

  @extend %font-copy;

  ul {
    list-style: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  @include breakpoint(tab) {
    max-width: 300px;
    padding: 10px 30px 30px;
  }
}

.menu-button {
  background-color: transparent;
  border-radius: 0;
  border: 0;
  height: var(--navbutton-height);
  padding: 0;
  // position: sticky;
  // top: 5px;
  width: 50px;

  img {
    padding: 5px;
    width: 100%;
  }

  @include breakpoint(tab) {
    position: relative;
    left: unset;
  }
}

.Nav-toggle {
  position: fixed;
  top: 5px;
  left: 10px;
  z-index: 3;

  @include breakpoint(tab) {
    left: 30px;
    top: 5px;
  }
}

.Nav-menu {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px - 50px); // 100vh - padding - button
  justify-content: flex-start;
  padding: 1rem 0.5rem;

  a {
    margin: 0.5em 0;
  }

  > li:not(.Nav-menu-subnav) {
    margin: 0.5em 0;
    text-transform: uppercase;
  }

  @include breakpoint(tab) {
    padding: 1rem 0 0;
  }
}

.Nav-menu-subnav {
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin: 1rem 0;

  @include breakpoint(tab) {
    margin: 0.5rem 0;
    padding: 0.5rem 0;
  }

  a {
    display: block;
  }
}
.Nav-menu-subnav-group {
  margin: 1em 0;
  > a {
    padding-left: 1em;
  }
}
.Nav-menu-subnav-group-heading {
  text-transform: uppercase;
}

.Nav-buttons {
  display: flex;
  position: fixed;
  top: 5px;
  right: 10px;
  z-index: 3;

  .menu-button {
    cursor: pointer;
  }

  .menu-button:hover * {
    filter: brightness(90%);
  }

  svg {
    padding: 10px;
    display: block;
    width: 100%;
    height: 100%;
  }
  @include breakpoint(tab) {
    top: 5px;
    svg {
      padding: 5px;
    }
  }
}

.Share {
  position: relative;

  .SocialMediaShareButton svg {
    * {
      fill: var(--indexblue);
    }
  }
}

.Share-buttons {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  // min-height: calc(100vh - var(--navbutton-height) - 5px);
  .SocialMediaShareButton {
    cursor: pointer;
  }
}
