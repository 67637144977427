@import "~scss/imports.scss";

.ColorKey {
  align-items: center;
  display: flex;
  max-width: 800px;
  margin-bottom: 0.5em;

  ul {
    flex-wrap: wrap;
    align-items: center;
  }

  h3 {
    margin-right: 1rem;
    @extend %font-chartaxis;
  }

  @extend %font-copymono;
}

.ColorKey-key {
  display: grid;
  grid-template-columns: 25px auto;
  grid-gap: 5px;
  // width: 50%;
  margin: 0.5em 0.5em;
}

.ColorKey-circle {
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  width: 20px;
}

.MovingMap-ChartKey {
  padding-left: 2rem;
}
