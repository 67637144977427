@import "~scss/imports";

.NumberBlock {
  list-style: none;
  padding: 1rem 0;

  > div {
    padding-bottom: 100%;
    position: relative;
  }
}

.NumberBlock-wrap {
  align-items: center;
  display: flex;
  height: calc(100% - 80px);
  justify-content: center;
}

.NumberBlock-number {
  @extend %font-bignumber;
  font-size: 48px;
  align-items: center;
  display: flex;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  @include breakpoint(tab) {
    font-size: 120px;
  }
}
