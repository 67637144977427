%max {
  max-width: 1000px;
  margin: 0 auto;
}

%sidebar {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 1rem;
}
