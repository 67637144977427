.Select {
  background-color: transparent;
  appearance: none;
  display: block;
  font-size: 100%;
  border-radius: 0;
  border: 2px solid;
  background-image: url(svg/selectarrow.svg);
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  background-position: calc(100% - .25rem) 50%;
  padding: 1rem 1.5rem 1rem .5rem;
  text-align: center;
}

.Tooltip {
  opacity: 1 !important;
}

.Flex-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

