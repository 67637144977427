@import "~scss/imports";

.Scatterplot {
  position: relative;
  padding-left: 4em;
}
.Scatterplot-x,
.Scatterplot-y {
  @extend %font-copymono;
  max-width: 30em;
  text-align: center;
  margin: 0 auto;
}
.Scatterplot-y {
  position: absolute;
  transform: rotate(-90deg) translate(-50%);
  transform-origin: top left;
  left: 0;
  bottom: calc(50% - 3em);
}
.Scatterplot-x {
  margin-bottom: 2em;
  text-align: center;
}

.Scatterplot-tooltip {
  background-color: #fff;
}

.Scatterplot-mobile {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1rem;
}

.Scatterplot-mobile-header {
  border-bottom: 1px solid var(--indexblue);
  grid-column: 2;
  padding-bottom: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  @extend %font-chartaxis;
}

.Scatterplot-mobile-x-axis {
  @extend %font-copymono;
  display: flex;
  grid-column: 2;
  list-style: none;

  li {
    text-align: center;
    width: 50%;
  }
}

.Scatterplot-mobile-y-axis {
  @extend %font-smallcopy;
  list-style: none;

  p {
    text-align: center;
  }
}

.Scatterplot-mobile-data {
  list-style: none;
  grid-column: 1 / span 2;

  li {
    align-items: center;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 2fr;
    justify-items: center;
    margin-bottom: 1rem;
  }
}

.Scatterplot-mobile-graphs {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  grid-gap: 1rem;

  svg {
    height: auto;
    width: 100%;

    text {
      @extend %font-chartnumber;
    }
  }
}
