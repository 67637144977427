@import "~scss/imports.scss";
@import "~scss/type.scss";
@import "~scss/layout.scss";

.BarGraph {
  font-family: $ff-regular;
  margin-top: 80px;

  .ShowMore {
    display: flex;
    justify-content: center;
  }
}

.BarGraph--desktop {
  display: grid;
  grid-template-columns: 20% 1fr;
  grid-column-gap: 2rem;

  .ShowMore {
    grid-column: 1 / -1;
    margin: auto;
  }
}

.BarGraph {
  .ShowMore {
    margin-top: 1em;
  }
}

.BarGraph-Graph {
  text {
    @extend %font-chartnumber;
  }
}

.BarGraph-Axis--Y {
  list-style: none;
  margin: 0;
  padding: 0;

  @extend %font-chartaxis;
}

.MobileBarGraph {
  @extend %font-chartnumber;

  & + .MobileBarGraph {
    margin-top: 1em;
  }
}
