@import "~scss/imports.scss";

.DotComparison {
  max-width: 400px;
  margin: auto;

  @include breakpoint(tab) {
    > div {
      padding: 0 3rem;
    }
    svg {
      display: block;
      margin: auto;
    }
  }
}
.DotComparison--multiple {
  max-width: 800px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
}

.DotMatrix-value {
  @extend %font-keyfindingheading;
  margin: 1rem 0;
  text-align: center;
}

.DotMatrix-label {
  @extend %font-copymono;
  padding: 0 1rem;
}
