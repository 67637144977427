@import "~scss/imports";

.CityPage-subnav {
  background-color: white;
  position: sticky;
  top: var(--globalheader-height);
  z-index: 1;
}
.CityPage-back {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  display: block;
  margin: 0 auto;
  padding: 1em;
  cursor: pointer;

  @extend %font-copymono;
  &:hover {
    color: var(--indexblue);
  }
}

.CityPage-inner {
  padding: 0 1rem 1rem;

  @include breakpoint(desk) {
    padding: 0 50px 50px;
  }
}

.CityPage-indeces {

  @include breakpoint(tab) {
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }

  @include breakpoint(desk) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
  }
}
