@import "~scss/imports.scss";

.PercentComparisonCards {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  align-items: end;

  @include breakpoint(tab) {
    display: grid;
  }
}

.PercentComparisonCards-label {
  @extend %font-subhead;
  margin: 1rem 0 2rem;
  min-height: 50px;
  text-align: center;

  @include breakpoint(tab) {
    margin: 2rem 0 0;
  }
}

.PercentComparisonCards-value {
  font-family: var(--apercu-mono);
}
