@import "~scss/imports";

.wework-fow-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > svg {
    margin: 1em;
  }

  @media (min-width: $break-mobile) {
    flex-direction: row;

    > svg:nth-child(2) {
      margin-top: 34px;
    }
  }
}
