@import "~scss/imports.scss";

$height: 60px;

.CityList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px,1fr));
  grid-gap: 2px;
}

.CityList-item {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: .5rem;
  min-height: $height;
  text-align: center;

  a {
    color: #000000;
    text-decoration: none;
  }

  @extend %font-smallcopy;
}

.ScoreCities {
  display: grid;
  grid-gap: 2px;
}

.ScoreCities-item .CityList {
  margin-top: 2px;
}

.ScoreCities-item-button {
  border: 0;
  border-radius: 0;

  display: block;
  width: 100%;
  min-height: $height;
  padding: .5rem;
  font-size: 100%;

  @extend %font-smallcopy;
}
