@import "~scss/imports";

.Footer {
  background-color: var(--tan);
  margin-top: 2rem;
}

.Footer-inner {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  >*+* {
    margin-top: 2rem;
  }
}

.Footer-heading {
  @extend %font-headline-2;
}

.Footer-text {
  @extend %font-copy;
}
