@import "~scss/imports.scss";

:root {
  --index-row-height: 50px;
}

.CityIndex {
  > div {
    height: 80vh;
  }
  .tooltip {
    position: absolute;
    z-index: 1;
  }
}

.Grid {
  height: 100%;
  width: 100%;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.CityIndex-controls {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    > button {
      display: block;
    }
  }
}

.CityIndex-mobile + .CityIndex-mobile {
  margin-top: 2rem;
}

.CityIndex .Masonry {
  list-style-type: none;
  padding: 0;
  position: relative;
}

.CityIndex-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.CityIndex-dimension-toggle {
  min-height: var(--index-row-height);
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  border-top: 1px solid var(--gray);

  @extend %font-copymono;

  &:hover {
    background-color: var(--tan);
  }
}
.CityIndex-dimension-toggle-question {
  @extend %font-copy;

  color: var(--indexblue);
  padding: 2rem;
  text-align: center;
  font-size: 24px;
  &:before {
    content: "“";
  }
  &:after {
    content: "”";
  }
}

.TotalIndex-row {
  display: flex;
}

.TotalIndex-row > div {
  width: 100%;
}

.Masonry-item {
  list-style-type: none;
  padding: 0;
  position: absolute;
  will-change: transform, width, height, opacity;
  text-align: center;
  height: var(--index-row-height);
}

.Grid {
  display: block;
}

.CityTile {
  border: 1px solid black;
}

.CityIndexInteractive {
  //height: 80vh;
  //display: flex;
}
.CityIndexInteractive .CityIndex {
  @extend %sidebar;
}
.CityIndexScroller {
  @extend %sidebar;

  .CityIndexScroller-tiles {
    align-items: center;
    display: flex;
    height: 80vh;
    position: sticky;
    top: var(--globalheader-height);

    .CityIndex {
      //height: 80vh;
      height: 100%;
      width: 100%;
    }
  }
}

.CityIndexScroller-items {
  .CityIndexScrollerTrigger {
    min-height: 80vh;
  }
}

.CityIndexScrollerTrigger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .HowDidWe {
    order: 2;
    margin-top: 1em;
  }

  @include breakpoint(tab) {
    text-align: center;
  }
}

.CityIndexScrollerTrigger-ratio {
  font-size: 2em;
  @extend %font-headline-2;
  margin-top: 1rem;

  @include breakpoint(desk) {
    margin-top: 0;
  }
}
.CityIndexScrollerTrigger-ratiolabel {
  font-size: 1.25em;
  @extend %font-subhead;
}

.CityIndexScrollerTrigger-text {
  @extend %font-smallcopy;
  margin-top: 0.5rem;
  p + p {
    margin-top: 1em;
  }
  a {
    color: inherit;
  }
}

.CityIndexMobile .Select {
  border-color: var(--indexblue);
}
.CityIndexMobile-results {
  display: grid;
  grid-template-columns: 2rem 1fr;
}

.CityIndexMobile-results-key {
  position: relative;
  text-transform: uppercase;

  @extend %font-chartaxis;

  div {
    white-space: nowrap;
    position: absolute;
    width: 1rem;
  }

  div:first-child {
    transform-origin: top left;
    transform: rotate(-90deg) translate(-6rem, 0.4rem);
    top: 0;
  }
  div:last-child {
    transform-origin: top left;
    transform: rotate(-90deg) translate(-1rem, 0.4rem);
    bottom: 0;
  }
}

.CityIndexMobile-button {
  background-color: transparent;
  border: 0;
  color: var(--indexblue);
  padding: 1rem 0;

  -webkit-appearance: none;

  @extend %font-mediumcopy;

  &.is-open {
    svg {
      transform: rotate(90deg);
    }
  }

  svg {
    height: auto;
    margin-left: 5px;
    max-width: 7px;
    width: 100%;
  }
}

.HowDidWeButton {
  display: block;
  position: absolute;
  right: 30px;
  top: 12rem;
}

.HowDidWe-Copy {
  background-color: var(--indexblue);
  color: #fff;
  margin-bottom: 1rem;
  padding: 1rem;
  @extend %font-copymono;
}
.HowDidWe {
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid var(--indexblue);
  color: var(--indexblue);
  height: 25px;
  padding: 3px 0;
  width: 25px;
  @extend %font-copymono;
}
.HowDidWeButton-tooltip {
  background-color: #fff !important;
  border: 1px solid var(--indexblue);
  border-radius: 0;
  color: var(--indexblue) !important;
  opacity: 1 !important;
  padding: 0.5rem 1rem;
  max-width: calc(100% - 4rem);
  // min-width: 300px;
  @extend %font-copymono;

  &:before {
    border: none !important;
  }
  &:after {
    border: none !important;
  }

  @media (min-width: 500px) {
    padding: 1rem 2rem;

    max-width: 400px;
  }
}
.__react_component_tooltip .multi-line {
  text-align: left;
  padding: 0;

  &:empty {
    margin-top: 1em;
  }
  & + .multi-line {
    // margin-top: 1em;
  }
}
.CityIndex-buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.CityIndexScroll-HowDidWeButton {
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid var(--indexblue);
  color: var(--indexblue);
  height: 25px;
  padding: 3px 0;
  width: 25px;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-open {
    background-color: var(--indexblue);
    color: #fff;
  }

  @include breakpoint(tab) {
    &:hover {
      background-color: var(--indexblue);
      color: #fff;
    }
  }

  @extend %font-copymono;
}

.CityIndexMobile-HowDidWeButton {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
