@import "~scss/imports";

.TextBlock {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
}

.TextBlock-wrapper {
  margin: 0 auto;
  max-width: 800px;
  padding: 1rem;
  text-align: center;
  width: 100%;
}

.TextBlock-header {
  @extend %font-headline-2;
  margin-bottom: 0.5rem;
}

.TextBlock-header + .TextBlock-text {
  padding-top: 2rem;
}

.TextBlock-text {
  @extend %font-mediumcopy;
  margin: 0 auto;
  max-width: 550px;
}

.TextBlock-double {
  &.TextBlock-wrapper {
    max-width: 550px;
  }

  .TextBlock-header {
    @extend %font-bigcopy;
  }

  .TextBlock-text {
    @extend %font-smallcopy;
  }
}
