@import "~scss/imports";

.QuoteGrid {
  padding: 1rem;
  margin-top: 1rem;

  @include breakpoint(tab) {
    padding: 1rem 2rem;
  }
}

.QuoteGrid-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  .Quote {
    margin: 1rem;
    width: calc(100% - 2rem);
  }

  @include breakpoint(tab) {
    .Quote {
      padding: 0.5rem 0;
      width: calc(33% - 2rem);
    }
  }

  @include breakpoint(desk) {
    .Quote {
      width: calc(20% - 2rem);
    }
  }
}

.QuoteGrid-header {
  border-bottom: 1px solid var(--indexblue);
  display: block;
  padding-bottom: 0.25rem;
  margin: 0 1rem;
  @extend %font-headline-2;
}

.Quote-heading {
  @extend %font-subhead;
  margin-bottom: 0.5rem;
}

.Quote-text {
  @extend %font-copymono;
}
