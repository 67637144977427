@import "~scss/imports";

.Missing {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  text-align: center;
  @extend %font-copy;

  p + p {
    margin-top: 1em;
  }
}
