@import "~scss/imports.scss";

.FlowChart {
  font-family: $ff-medium;

  svg {
    height: auto;
    width: 100%;
  }
}

.FlowChart-Svg {
  display: grid;
  grid-template-columns: 25% auto;
  margin-bottom: 10px;

  text {
    @extend %font-chartnumber;
    font-size: 2rem;
  }
}

.FlowChart-Axis--X {
  display: grid;
  grid-template-columns: 2fr 2fr 0;
  grid-gap: 1rem;
  list-style: none;
  padding: 0 0 1rem;
  margin-left: auto;
  text-transform: uppercase;
  width: 75%;


  @include breakpoint(tab) {
    grid-template-columns: 2fr 2fr 1rem;
  }

  @extend %font-chartaxis;

  li {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.FlowChart-Axis--Y {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
  // width: 25%;

  @extend %font-chartaxis;

  li {
    text-align: center;
  }

  @include breakpoint(tab) {
    justify-content: center;
  }
}
