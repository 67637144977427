@import "~scss/imports";

.TextBox {
  border: 1px solid var(--indexblue);
  padding: 1rem;
  margin: 2rem 0;

  @include breakpoint(tab) {
    padding: 2rem;
  }

  .Heading {
    @extend %font-findingheading;
    padding: 1rem;
    text-align: center;
  }

  .Text {
    @extend %font-copy;
    max-width: 600px;
    text-align: center;
    margin: 0 auto;
    padding: 1rem;
  }

  div + h2 {
    padding-top: 2rem;
  }
}

.Text {
  p + p {
    margin-top: 1em;
  }
}

.Transition {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .Text {
    @extend %font-bigcopynormal;
  }
}
