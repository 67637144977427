@import "~scss/imports.scss";

.Comparison {
  @include breakpoint(tab) {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: 1rem;
  }
}

.Comparison-heading {
  @extend %font-bigcopy;
}

.Comparison-item {
  &+.Comparison-item {
    margin-top: 2rem;
  }
}

.Comparison-item-viz {
  margin-top: .25rem;
  rect {
    transition: fill .3s;
  }
}

.ComparisonKey {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  padding: 1rem 0;

  @include breakpoint(tab) {
    padding: 0;
  }
}

.ComparisonKey-label {
  margin-right: .5rem;
  text-transform: uppercase;
  @extend %font-chartaxis;
}

.ComparisonKey-item {
  padding: .5rem .5rem;
  text-align: center;
  margin-left: .5rem;
  @extend %font-copymono;

  @include breakpoint(tab) {
    min-width: 6rem;
  }
}

.Comparison-item-label {
  @extend %font-copymono;
}

.ComparisonTooltip {
  max-width: calc(100vw - 1rem);
  @include breakpoint(tab) {
    max-width: none;
  }
}

.ComparisonTooltip-inner-list-item {
  align-items: center;
  display: flex;
  margin-top: .5em;
  @extend %font-copymono;
}
.ComparisonTooltip-inner {
  padding: .75rem;
}
.ComparisonTooltip-inner-region {
    @extend %font-copy;
  text-align: center;
  margin-bottom: .75rem;
}
.ComparisonTooltip-inner-list {

}
.ComparisonTooltip-inner-list-item-value {
  width: 15px;
  height: 30px;
  flex-shrink: 0;
  margin-right: .25rem;
}
